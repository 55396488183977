import React from 'react';
import teamOne from '../../assets/images/TeresaTeam.png';
import teamTwo from '../../assets/images/luisTeamBorder.png';

function TeamHomeOne({ className }) {
    return (
        <>
            <section id="nosotros" className={`appie-team-area pt-90 pb-100 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Nuestro equipo</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row CardImg">
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-team-item mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={teamOne} alt="" />
                                    <ul>
                                        <li>
                                            <a href="https://www.linkedin.com/in/trsmto">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="appie-team-item mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="thumb">
                                    <img
                                        src={teamTwo}
                                        alt="https://www.linkedin.com/in/luis-tremont"
                                    />
                                    <ul>
                                        <li>
                                            <a href="https://www.linkedin.com/in/luis-tremont">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="team-btn text-center mt-50">
                                Las personas confían en el rostro, y Teresa Remesas es un
                                emprendimiento familiar que surge en Agosto del 2019 gracias a la
                                oportunidad de ofrecer servicios de envío de dinero a Venezuela,
                                inicialmente desde Colombia. Sus principales colaboradores son
                                Teresa y Luis pero detrás de la marca hay un equipo corredores que
                                prestan sus servicios como asesores para así atender a más de 200
                                clientes que envían su ayuda a sus familias en Venezuela.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TeamHomeOne;
