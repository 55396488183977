import React from 'react';
import caribe from '../../assets/images/banks/banCaribe.png';
import banesco from '../../assets/images/banks/banesco.png';
import mercantil from '../../assets/images/banks/mercantil.png';
import credito from '../../assets/images/banks/nacionalCredito.png';
import provincial from '../../assets/images/banks/provincial.png';
import venezuela from '../../assets/images/banks/venezuela.png';

function BankWeWork({ className }) {
    return (
        <>
            <section className={`appie-team-area pt-90 pb-100 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Bancos con los que trabajamos</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row CardImg">
                        <div className="col-lg-2 col-md-6 col-sm-4">
                            <div className="appie-team-item mt-30">
                                <img src={banesco} alt="https://www.banesco.com/" />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-4">
                            <div className="appie-team-item mt-30">
                                <img
                                    src={venezuela}
                                    alt="https://www.bancodevenezuela.com/index.html"
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-4">
                            <div className="appie-team-item mt-30 ">
                                <img
                                    src={provincial}
                                    alt="https://www.provincial.com/personas.html"
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-4">
                            <div className="appie-team-item mt-30 ">
                                <img
                                    src={mercantil}
                                    alt="https://www.mercantilbanco.com/mercprod/index.html"
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-4">
                            <div className="appie-team-item mt-30   ">
                                <img src={credito} alt="https://www.bncenlinea.com/" />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-4">
                            <div className="appie-team-item mt-30">
                                <img src={caribe} alt="https://www.bancaribe.com.ve/" />
                            </div>
                        </div>

                        <div className="col-lg-12 text-center">
                            <div className="team-btn text-center mt-50">
                                <p
                                    className="text-center"
                                    style={{
                                        width: '80%',
                                        display: 'inline-flex',
                                        fontSize: '25px',
                                    }}
                                >
                                    Solo realizamos pagos efectivos al instante a través de los
                                    bancos que indicamos en el listado. Si tu banco no aparece, te
                                    recomendamos utilizar el servicio de pago móvil para una
                                    transacción rápida y segura.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BankWeWork;
