/* eslint-disable react/button-has-type */
import React from 'react';
import Modal from 'react-modal';

// eslint-disable-next-line consistent-return
const ModalComponent = ({ isOpen, closeModal, data }) => {
    console.log(2312); // Coloca el console.log dentro de llaves {}
    if (!data || !data.modal || !data.modal[0]) {
        return '';
    }

    console.log(data.modal[0]);
    const profilePhotoUrl = `https://dash.teresaremesas.com${data.modal[0].profilePhoto}`;

    return (
        <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel="ImagenModal">
            <img src={profilePhotoUrl} alt="Imagen Modal" />
            <button className="BottonCerraModal" onClick={closeModal}>
                Cerrar
            </button>
        </Modal>
    );
};

export default ModalComponent;
