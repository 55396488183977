import axios from 'axios';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import ServicesHomeTwo from '../HomeTwo/ServicesHomeTwo';
import Drawer from '../Mobile/Drawer';
import BankWeWork from './BankWeWork';
import BlogHomeOne from './BlogHomeOne';
import FaqHomeOne from './FaqHomeOne';
import FooterHomeOne from './FooterHomeOne';
import HeroHomeOne from './HeroHomeOne';
import HomeOneHeader from './HomeOneHeader';
import ModalComponent from './ModalComponent';
import ServicesHomeOne from './ServicesHomeOne';
import TeamHomeOne from './TeamHomeOne';
import TestimonialHomeOne from './TestimonialHomeOne';
import TrafficHomeOne from './TrafficHomeOne';

function HomeOne() {
    const [drawer, drawerAction] = useToggle(false);
    const [data, setData] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(true);

    const notify = () => toast('✅ Se ha copiado con éxito');
    const closeModal = () => {
        setModalIsOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    //   'http://localhost:3001/api/resources/dolar/actions/list'
                    'https://dash.teresaremesas.com/api/resources/dolar/actions/list'
                );
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader action={drawerAction.toggle} />
            <HeroHomeOne data={data} />
            <ServicesHomeOne data={data} />
            <TrafficHomeOne notify={notify} data={data} />
            <BankWeWork />
            <BlogHomeOne />
            <TestimonialHomeOne />
            <ServicesHomeTwo data={data} />
            <TeamHomeOne />
            <FaqHomeOne />
            <FooterHomeOne />
            <BackToTop />
            <ModalComponent data={data} isOpen={modalIsOpen} closeModal={closeModal} />
            <Toaster position="bottom-center" reverseOrder={false} />
        </>
    );
}

export default HomeOne;
