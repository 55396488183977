import axios from 'axios';
import React, { useEffect, useState } from 'react';

function FaqHomeOne({ className }) {
    const [questions, setQuestions] = useState([]);
    const [showQues, setQues] = useState(1);
    useEffect(() => {
        // Realizar la solicitud a la API y actualizar el estado con las preguntas
        axios
            // .get('http://localhost:3001/api/resources/FAQ/actions/list')
            .get('https://dash.teresaremesas.com/api/resources/FAQ/actions/list')
            .then((response) => {
                setQuestions(response.data.data);
            })
            .catch((error) => {
                console.error('Error al obtener preguntas desde la API:', error);
            });
    }, []);
    const openQuestion = (value) => {
        setQues(value);
    };
    return (
        <>
            <section id="faq" className={`appie-faq-area pb-95 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title FAQ">Preguntas frecuentes</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {questions.map((question, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div className="col-lg-12" key={index}>
                                <div
                                    className="faq-accordion wow fadeInRight mt-30"
                                    data-wow-duration="1500ms"
                                >
                                    <div
                                        className="accrodion-grp animated fadeIn faq-accrodion wow"
                                        data-wow-duration="1500ms"
                                        data-grp-name="faq-accrodion"
                                    >
                                        <div
                                            onClick={() => openQuestion(index + 1)}
                                            className={`accrodion ${
                                                showQues === index + 1 ? 'active' : ''
                                            }`}
                                        >
                                            <div className="accrodion-inner">
                                                <div className="accrodion-title">
                                                    {' '}
                                                    <h4>
                                                        {index + 1}. {question.title}
                                                    </h4>
                                                </div>
                                                <div
                                                    className="accrodion-content"
                                                    style={{
                                                        display:
                                                            showQues === index + 1
                                                                ? 'block'
                                                                : 'none',
                                                    }}
                                                >
                                                    <div className="inner">
                                                        <p>{question.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default FaqHomeOne;
