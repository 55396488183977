import React from 'react';

function ServicesHomeOne({ className, data }) {
    if (!data) {
        // Si 'data' es null, muestra un mensaje o componente de carga
        return (
            <div className={`appie-service-area pt-90 pb-100 ${className}`} id="service">
                Cargando datos...
            </div>
        );
    }

    const principalCotizador = [
        {
            label: 'Dólar Paralelo',
            value: data.data[0].tasaParalelo,
            profilePhoto: data.data[0].profilePhoto,
        },
        {
            label: 'Dólar BCV',
            value: data.data[0].tasaCentral,
            profilePhoto: data.data[0].profilePhoto,
        },
    ];

    if (data.country) {
        data.country.forEach((countryData) => {
            principalCotizador.push({
                label: countryData.countryName,
                value: countryData.tasa,
                profilePhoto: countryData.profilePhoto,
            });
        });
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        // Obteniendo los componentes de la fecha
        const day = date.getDate();
        const month = date.getMonth() + 1; // Los meses en JavaScript son 0-indexed
        const year = date.getFullYear();
        let hour = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hour >= 12 ? 'pm' : 'am';
        hour %= 12;
        hour = hour || 12; // La hora '0' debe mostrarse como '12'
        const formattedDate = `${day}/${month}/${year} ${hour}:${
            minutes < 10 ? `0${minutes}` : minutes
        }${ampm}`;
        return formattedDate;
    };

    const { ultimaActualizacion } = data.data[0];

    const formattedLastUpdate = formatDate(ultimaActualizacion);

    return (
        <section id="tasas" className={`appie-service-area pt-90 pb-100 ${className}`}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">Tasas del día</h3>
                            <p id="UltimaActualizacion" className="appie-title">
                                Última actualización {formattedLastUpdate}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="divCotizador">
                    <div className="row Cotizador">
                        <div className="col-lg-12 header">
                            <div className="countryRate-header d-flex">
                                <div className="country-name col-lg-6">País</div>
                                <div className="rate col-lg-6">Tasa</div>
                            </div>
                        </div>
                        <div className="row col-lg-12 body">
                            {principalCotizador.map((option, index) => (
                                <div
                                    key={option.label}
                                    className={`col-lg-12 ${index % 2 === 0 ? 'bg-white' : ''}`}
                                >
                                    <div className="country-rate d-flex col-lg-12">
                                        <div className="country-name col-lg-6">
                                            {' '}
                                            <img
                                                // src={` http://localhost:3001${option.profilePhoto}`}
                                                src={` https://dash.teresaremesas.com${option.profilePhoto}`}
                                                alt={option.label}
                                                className="country-icon"
                                            />
                                            {option.label}
                                        </div>
                                        <div className="rate col-lg-6">{option.value}</div>
                                    </div>
                                </div>
                            ))}
                            <div className="col-lg-12 footerSpace"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
