import React from 'react';
import logo from '../../assets/images/LogoTeresa.png';

function FooterHomeOne({ className }) {
    return (
        <>
            <section className={`appie-footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <a href="#">
                                        <img src={logo} alt="" />
                                    </a>
                                </div>
                                <p>Si quieres enviar una remesa, contáctanos</p>
                                <div className="footer-widget-info">
                                    <ul>
                                        <li>
                                            <a href="https://wa.me/573203561490">
                                                <i className="fal fa-phone" /> +57 320 356 1490
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/teresaremesas/">
                                                <i className="fab fa-instagram" /> @TeresaRemesas
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title withLine">Páginas</h4>
                                <ul>
                                    <li>
                                        <a href="#home">Home</a>
                                    </li>
                                    <li>
                                        <a href="#tasas">Tasas</a>
                                    </li>
                                    <li>
                                        <a href="#horarios">Horarios</a>
                                    </li>
                                    <li>
                                        <a href="#servicios">Servicios</a>
                                    </li>
                                    <li>
                                        <a href="#nosotros">Nosotros</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="copyright-text">
                                    <p>
                                        Todos los derechos reservados © 2024 TeresaRemesas. Designer
                                        By <a href="http://titotremont.com/"> TitoTremont</a> and
                                        Programed By <a href="https://codembody.com">Codembody</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeOne;
