/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import UsaPng from '../../assets/images/icon/UsaIcon.png';
import VenPng from '../../assets/images/icon/VenezuelaIcon.png';

function formatearMonto(monto) {
    const montoReturn = Number(monto).toFixed(2);

    return montoReturn
        .toString()
        .replace(/\./, 'x') // Temporalmente reemplaza el punto decimal
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.') // Separador de miles con punto
        .replace(/x/, ','); // Reemplaza el punto decimal temporal con coma
}
function HeroHomeOne({ className, data }) {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [stepPluginActive, setStepPluginActive] = useState(1);
    const [flagImage, setFlagImage] = useState('');
    const [inputValue, setInputValue] = useState(20000);
    const [inputValueCompara, setInputValueCompara] = useState(20);
    const [amountReceive, setAmountReceive] = useState(0);
    const [amountBCV, setAmountBCV] = useState(0);
    const [amountParalelo, setAmountParalelo] = useState(0);
    const [dataDOLAR, setdataDOLAR] = useState(null);
    const [cantidadAEnviar, setCantidadAEnviar] = useState(0);
    const [dolarArecibir, setDolarARecibir] = useState(0);
    const [cantidadAEnviarPARA, setCantidadAEnviarPARA] = useState(0);
    const [dolarArecibirPARA, setDolarARecibirPARA] = useState(0);

    console.log(selectedCountry);
    console.log(data);

    useEffect(() => {
        if (selectedCountry) {
            if (selectedCountry.factor_calculate === 'divide') {
                const monto = inputValue / selectedCountry.tasa;
                const montoFormateado = formatearMonto(monto);
                const amountBCVp = monto / dataDOLAR.tasaCentral;
                const amountBCVpFormateado = formatearMonto(amountBCVp);
                const amountParalelop = monto / dataDOLAR.tasaParalelo;
                const amountParaleloFormateado = formatearMonto(amountParalelop);
                setAmountReceive(montoFormateado);
                setAmountBCV(amountBCVpFormateado);
                setAmountParalelo(amountParaleloFormateado);
            }
            if (selectedCountry.factor_calculate === 'multiplica') {
                const monto = inputValue * selectedCountry.tasa;
                const montoFormateado = formatearMonto(monto);
                const amountBCVp = monto / dataDOLAR.tasaCentral;
                const amountBCVpFormateado = formatearMonto(amountBCVp);
                const amountParalelop = monto / dataDOLAR.tasaParalelo;
                const amountParaleloFormateado = formatearMonto(amountParalelop);
                setAmountReceive(montoFormateado);
                setAmountBCV(amountBCVpFormateado);
                setAmountParalelo(amountParaleloFormateado);
            }
        }
        console.log('Veamos');
        console.log(selectedCountry);
    }, [setSelectedCountry, setInputValue, selectedCountry, amountReceive, inputValue, dataDOLAR]);

    useEffect(() => {
        if (selectedCountry) {
            if (selectedCountry.factor_calculate === 'divide') {
                const cantidadrequerida = inputValueCompara * selectedCountry.tasa;
                const cantidadaenviar = cantidadrequerida * dataDOLAR.tasaCentral;
                setCantidadAEnviar(formatearMonto(cantidadaenviar));
                setDolarARecibir(formatearMonto(inputValueCompara * dataDOLAR.tasaCentral));
                // PARALELO
                const cantidadaenviarPARA = cantidadrequerida * dataDOLAR.tasaParalelo;
                setCantidadAEnviarPARA(formatearMonto(cantidadaenviarPARA));
                setDolarARecibirPARA(formatearMonto(inputValueCompara * dataDOLAR.tasaParalelo));
            }
            if (selectedCountry.factor_calculate === 'multiplica') {
                const cantidadrequerida = inputValueCompara / selectedCountry.tasa;
                const cantidadaenviar = cantidadrequerida * dataDOLAR.tasaCentral;
                setCantidadAEnviar(formatearMonto(cantidadaenviar));
                setDolarARecibir(formatearMonto(inputValueCompara * dataDOLAR.tasaCentral));
                // PARALELO
                const cantidadaenviarPARA = cantidadrequerida * dataDOLAR.tasaParalelo;
                setCantidadAEnviarPARA(formatearMonto(cantidadaenviarPARA));
                setDolarARecibirPARA(formatearMonto(inputValueCompara * dataDOLAR.tasaParalelo));
            }
        }
        console.log('Veamos');
        console.log(selectedCountry);
    }, [setSelectedCountry, setInputValueCompara, selectedCountry, inputValueCompara, dataDOLAR]);

    useEffect(() => {
        if (data && data.country) {
            setdataDOLAR(data.data[0]);
            const countries = data.country.map(
                // eslint-disable-next-line camelcase
                ({ moneda, profilePhoto, tasa, factor_calculate, simbolo }) => ({
                    tasa,
                    label: moneda,
                    simbolo,
                    factor_calculate,
                    //    icon: `http://localhost:3001${profilePhoto}`,
                    icon: `https://dash.teresaremesas.com${profilePhoto}`,
                })
            );
            setSelectedCountry(countries[0]);
            setFlagImage(countries[0].icon);
        }
    }, [data]);

    useEffect(() => {
        if (selectedCountry) {
            setFlagImage(selectedCountry.icon);
        }
    }, [selectedCountry]);

    const handleCountryChange = (e) => {
        setInputValue(0);
        if (data && data.country) {
            const countries = data.country.map(
                // eslint-disable-next-line camelcase
                ({ moneda, profilePhoto, tasa, factor_calculate, simbolo }) => ({
                    tasa,
                    label: moneda,
                    simbolo,
                    factor_calculate,
                    //   icon: `http://localhost:3001${profilePhoto}`,
                    icon: `https://dash.teresaremesas.com${profilePhoto}`,
                })
            );
            const country = countries.find((c) => c.label === e.target.value);
            setSelectedCountry(country);
            console.log('Seleccionada');
            console.log(country);
        }
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };
    const handleInputChangeCompara = (e) => {
        setInputValueCompara(e.target.value);
    };

    if (!data) {
        return 'No hay data disponible';
    }

    const handleCompartir = async () => {
        try {
            // Si el navegador soporta la API Web Share
            if (navigator.share) {
                await navigator.share({
                    title: 'Me gustaria enviar',
                    text: `Hola, quiero cambiar ${inputValue} ${selectedCountry.label} a ${amountReceive} bs`,
                });
            } else {
                // Si no, abre un enlace para compartir en WhatsApp
                const text = `Hola, quiero cambiar ${inputValue} ${selectedCountry.label} a ${amountReceive} bs`;
                const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(text)}`;

                // Abre el enlace en una nueva ventana/tab
                window.open(whatsappUrl, '_blank');
            }
        } catch (error) {
            console.error('Error al compartir:', error);
        }
    };
    const handleCompartirBCV = async () => {
        try {
            // Si el navegador soporta la API Web Share
            if (navigator.share) {
                await navigator.share({
                    title: 'Me gustaria enviar',
                    text: `Hola, quiero cambiar  ${cantidadAEnviar} ${selectedCountry.label} a ${dolarArecibir} bs`,
                });
            } else {
                // Si no, abre un enlace para compartir en WhatsApp
                const text = `Hola, quiero cambiar  ${cantidadAEnviar} ${selectedCountry.label} a ${dolarArecibir} bs`;
                const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(text)}`;

                // Abre el enlace en una nueva ventana/tab
                window.open(whatsappUrl, '_blank');
            }
        } catch (error) {
            console.error('Error al compartir:', error);
        }
    };

    const handleCompartirPARA = async () => {
        try {
            // Si el navegador soporta la API Web Share
            if (navigator.share) {
                await navigator.share({
                    title: 'Me gustaria enviar',
                    text: `Hola, quiero cambiar ${cantidadAEnviarPARA} ${selectedCountry.label} a ${dolarArecibirPARA} bs`,
                });
            } else {
                // Si no, abre un enlace para compartir en WhatsApp
                const text = `Hola, quiero cambiar ${inputValue} ${selectedCountry.label} a ${dolarArecibirPARA} bs`;
                const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(text)}`;

                // Abre el enlace en una nueva ventana/tab
                window.open(whatsappUrl, '_blank');
            }
        } catch (error) {
            console.error('Error al compartir:', error);
        }
    };
    // eslint-disable-next-line camelcase
    const countries = data.country.map(
        // eslint-disable-next-line camelcase
        ({ moneda, profilePhoto, tasa, factor_calculate, simbolo }) => ({
            tasa,
            label: moneda,
            simbolo,
            factor_calculate,
            //  icon: `http://localhost:3001${profilePhoto}`,
            icon: `https://dash.teresaremesas.com${profilePhoto}`,
        })
    );

    return (
        <>
            <section id="calculadora" className={`appie-hero-area ${className || ''}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-hero-content">
                                <h1 className="appie-title">
                                    Enviamos <br></br> tu dinero en <br></br> tiempo récord
                                </h1>
                                <p>Danos el valor a calcular, nosotros hacemos lo demás</p>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex">
                            <div className="CalculadoraTeresa flex-column align-items-center">
                                <div className="col-lg-12 BodyPlugin d-flex justify-content-center">
                                    <div className="col-lg-6">
                                        <button
                                            className={`ButtonPlugin ${
                                                stepPluginActive === 1 ? 'Active' : 'Inactive'
                                            }`}
                                            onClick={() => setStepPluginActive(1)}
                                        >
                                            Calculadora
                                        </button>
                                    </div>
                                    <div className="col-lg-6">
                                        <button
                                            className={`ButtonPlugin ${
                                                stepPluginActive === 2 ? 'Active' : 'Inactive'
                                            }`}
                                            onClick={() => setStepPluginActive(2)}
                                        >
                                            Compara en dólares
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className={`col-lg-12 Calculadora d-flex ${
                                        stepPluginActive === 1 ? 'step1' : 'step2'
                                    } justify-content-center`}
                                >
                                    {stepPluginActive === 1 && (
                                        <div className="col-lg-12">
                                            {' '}
                                            <h5>Tú envías</h5>
                                            <div className="d-flex InputPluginSend align-items-center">
                                                <img src={flagImage} alt="bandera del país" />
                                                <select
                                                    name="pais"
                                                    id="pais"
                                                    onChange={handleCountryChange}
                                                >
                                                    {countries.map((country, index) => (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <option key={index} value={country.label}>
                                                            {country.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                <input
                                                    type="number"
                                                    name="cantidad"
                                                    id="cantidad"
                                                    className="sinFlechas"
                                                    value={inputValue}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <h5 style={{ marginTop: '10px' }}>Recibes</h5>
                                            <div className="d-flex InputPluginGet align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <img src={VenPng} alt="bandera del país" />
                                                    <p style={{ marginLeft: '10px' }}>VES</p>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <p style={{ marginLeft: '10px', fontSize: 15 }}>
                                                        Bs
                                                    </p>
                                                    <p style={{ marginLeft: 'auto', fontSize: 15 }}>
                                                        {amountReceive}
                                                    </p>
                                                </div>
                                            </div>
                                            <h5 style={{ marginTop: '10px' }}>Al cambio BCV</h5>
                                            <div className="d-flex InputPluginGet align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <img src={UsaPng} alt="bandera del país" />
                                                    <p style={{ marginLeft: '10px' }}>USD</p>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <p style={{ marginLeft: '10px', fontSize: 15 }}>
                                                        $
                                                    </p>
                                                    <p style={{ marginLeft: 'auto', fontSize: 15 }}>
                                                        {amountBCV}
                                                    </p>
                                                </div>
                                            </div>
                                            <h5 style={{ marginTop: '10px' }}>
                                                Al cambio Paralelo
                                            </h5>
                                            <div className="d-flex InputPluginGet align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <img src={UsaPng} alt="bandera del país" />
                                                    <p style={{ marginLeft: '10px' }}>USD</p>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <p style={{ marginLeft: '10px', fontSize: 15 }}>
                                                        $
                                                    </p>
                                                    <p style={{ marginLeft: 'auto', fontSize: 15 }}>
                                                        {amountParalelo}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className="d-flex"
                                                style={{ justifyContent: 'right' }}
                                            >
                                                <button
                                                    className="CompatirButton"
                                                    onClick={handleCompartir}
                                                >
                                                    Compartir monto
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                    {stepPluginActive === 2 && (
                                        <div className="col-lg-12">
                                            {' '}
                                            <h5>Necesitas en dólares</h5>
                                            <div className="d-flex InputPluginSend align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <img src={UsaPng} alt="bandera del país" />
                                                    <p style={{ marginLeft: '10px' }}>USD</p>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <p
                                                        className="PDeDolar"
                                                        style={{ marginLeft: '10px', fontSize: 15 }}
                                                    >
                                                        $
                                                    </p>
                                                    <input
                                                        type="number"
                                                        name="cantidad"
                                                        id="cantidad"
                                                        className="sinFlechas"
                                                        value={inputValueCompara}
                                                        onChange={handleInputChangeCompara}
                                                    />
                                                </div>
                                            </div>
                                            <h5 style={{ marginTop: '10px' }}>Al cambio BCV</h5>
                                            <div
                                                style={{ marginTop: '10px' }}
                                                className="col-lg-12 d-flex row DivWithBorder"
                                            >
                                                <div className="col-lg-6">
                                                    <h5>Debes enviar</h5>{' '}
                                                    <div className="d-flex InputPluginGet align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src={flagImage}
                                                                alt="bandera del país"
                                                            />
                                                            <p style={{ marginLeft: '10px' }}>
                                                                {selectedCountry.label}
                                                            </p>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <p
                                                                style={{
                                                                    marginLeft: '10px',
                                                                    fontSize: 15,
                                                                }}
                                                            >
                                                                {selectedCountry.simbolo}
                                                            </p>
                                                            <p
                                                                style={{
                                                                    marginLeft: 'auto',
                                                                    fontSize: 15,
                                                                }}
                                                            >
                                                                {cantidadAEnviar}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <h5>Recibes</h5>{' '}
                                                    <div className="d-flex InputPluginGet align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src={VenPng}
                                                                alt="bandera del país"
                                                            />
                                                            <p style={{ marginLeft: '10px' }}>
                                                                VES
                                                            </p>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <p
                                                                style={{
                                                                    marginLeft: '10px',
                                                                    fontSize: 15,
                                                                }}
                                                            >
                                                                Bs
                                                            </p>
                                                            <p
                                                                style={{
                                                                    marginLeft: 'auto',
                                                                    fontSize: 15,
                                                                }}
                                                            >
                                                                {dolarArecibir}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h5 style={{ marginTop: '10px' }}>
                                                {' '}
                                                Al cambio paralelo
                                            </h5>
                                            <div
                                                style={{ marginTop: '10px' }}
                                                className="col-lg-12 d-flex row DivWithBorder"
                                            >
                                                <div className="col-lg-6">
                                                    <h5>Debes enviar</h5>{' '}
                                                    <div className="d-flex InputPluginGet align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src={flagImage}
                                                                alt="bandera del país"
                                                            />
                                                            <p style={{ marginLeft: '10px' }}>
                                                                {selectedCountry.label}
                                                            </p>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <p
                                                                style={{
                                                                    marginLeft: '10px',
                                                                    fontSize: 15,
                                                                }}
                                                            >
                                                                {selectedCountry.simbolo}
                                                            </p>
                                                            <p
                                                                style={{
                                                                    marginLeft: 'auto',
                                                                    fontSize: 15,
                                                                }}
                                                            >
                                                                {cantidadAEnviarPARA}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <h5>Recibes</h5>{' '}
                                                    <div className="d-flex InputPluginGet align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src={VenPng}
                                                                alt="bandera del país"
                                                            />
                                                            <p style={{ marginLeft: '10px' }}>
                                                                VES
                                                            </p>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <p
                                                                style={{
                                                                    marginLeft: '10px',
                                                                    fontSize: 15,
                                                                }}
                                                            >
                                                                Bs
                                                            </p>
                                                            <p
                                                                style={{
                                                                    marginLeft: 'auto',
                                                                    fontSize: 15,
                                                                }}
                                                            >
                                                                {dolarArecibirPARA}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="d-flex"
                                                style={{ placeContent: 'space-evenly' }}
                                            >
                                                <button
                                                    className="CompatirButton"
                                                    onClick={handleCompartirBCV}
                                                >
                                                    Enviar a BCV
                                                </button>
                                                <button
                                                    className="CompatirButton"
                                                    onClick={handleCompartirPARA}
                                                >
                                                    Enviar a Paralelo
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroHomeOne;
