import React from 'react';

function ServicesHomeTwo({ className, data }) {
    if (!data || !data.service) {
        return <p>Data no disponible</p>;
    }
    return (
        <>
            <section className={`appie-services-2-area pb-100 ${className}`} id="servicios">
                <div className="container">
                    <div
                        className="row align-items-center"
                        style={{ textAlign: 'center', marginTop: 80 }}
                    >
                        <div className="col-lg-12 col-md-8">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Servicios</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ justifyContent: 'center' }}>
                        {data.service.map((service, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={index} className="col-lg-4 col-md-6">
                                <div
                                    className="appie-single-service-2 mt-30 wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay={`${200 * (index + 1)}ms`}
                                >
                                    <div className="icon Services">
                                        <img
                                            src={`https://dash.teresaremesas.com${service.profilePhoto}`}
                                            alt={service.title}
                                        />
                                    </div>
                                    <h4 className="title">{service.title}</h4>
                                    <p>{service.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesHomeTwo;
