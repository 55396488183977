import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

function TestimonialHomeOne({ className }) {
    const [FAQs, setFAQ] = useState([]);
    const sliderRef = useRef();
    const sliderNext = () => {
        sliderRef.current.slickNext();
    };
    const sliderPrev = () => {
        sliderRef.current.slickPrev();
    };

    useEffect(() => {
        // Realizar la solicitud a la API y actualizar el estado con las preguntas
        axios
            // .get('http://localhost:3001/api/resources/testimony/actions/list')
            .get('https://dash.teresaremesas.com/api/resources/testimony/actions/list')
            .then((response) => {
                setFAQ(response.data.data);
            })
            .catch((error) => {
                console.error('Error al obtener preguntas desde la API:', error);
            });
    }, []);
    return (
        <section
            className={`appie-testimonial-area pt-100 pb-160 ${className || ''}`}
            id="testimonial"
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="appie-testimonial-slider" style={{ position: 'relative' }}>
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Testimonios</h3>
                                <a
                                    style={{ marginTop: 20 }}
                                    className="main-btn main-btn-2 testimonyBTN"
                                    href="#"
                                >
                                    Ellos confían en nosotros
                                </a>
                                <span
                                    style={{
                                        display: 'block',
                                        marginTop: '20px',
                                        marginBottom: '20px',
                                        color: 'white',
                                    }}
                                >
                                    Cliente
                                </span>
                            </div>

                            <span
                                className="prev slick-arrow"
                                style={{ display: 'block' }}
                                onClick={sliderNext}
                                role="button"
                                tabIndex="0"
                            >
                                <i className="fal fa-arrow-left" />
                            </span>

                            <Slider ref={sliderRef} dots arrows={false}>
                                {FAQs.map((FAQ, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <div className="appie-testimonial-item text-center" key={index}>
                                        <div className="author-info">
                                            <img
                                                //    src={`http://localhost:3001${FAQ.profilePhoto}`}
                                                src={`https://dash.teresaremesas.com${FAQ.profilePhoto}`}
                                                alt=""
                                            />
                                            <h5 className="title">{FAQ.userName}</h5>
                                        </div>
                                        <div className="text">
                                            <p className="apoyoCita">"</p>
                                            <p>{FAQ.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                            <span
                                onClick={sliderPrev}
                                role="button"
                                tabIndex="-1"
                                className="next slick-arrow"
                                style={{ display: 'block' }}
                            >
                                <i className="fal fa-arrow-right" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TestimonialHomeOne;
