import React from 'react';

function Navigation() {
    // Función para manejar el clic en los enlaces internos
    const handleSmoothScroll = (event, targetId) => {
        event.preventDefault(); // Evitar el comportamiento predeterminado del enlace

        // Obtener el elemento de destino basado en el id
        const targetElement = document.getElementById(targetId);

        // Hacer desplazamiento suave hasta el elemento de destino
        targetElement.scrollIntoView({
            behavior: 'smooth', // Desplazamiento suave
            block: 'start', // Posición inicial del elemento
        });
    };

    return (
        <ul>
            <li>
                <a href="#home" onClick={(e) => handleSmoothScroll(e, 'home')}>
                    Home
                </a>
            </li>
            <li>
                <a href="#tasas" onClick={(e) => handleSmoothScroll(e, 'tasas')}>
                    Tasas
                </a>
            </li>
            <li>
                <a href="#horarios" onClick={(e) => handleSmoothScroll(e, 'horarios')}>
                    Horarios
                </a>
            </li>
            <li>
                <a href="#servicios" onClick={(e) => handleSmoothScroll(e, 'servicios')}>
                    Servicios
                </a>
            </li>
            <li>
                <a href="#nosotros" onClick={(e) => handleSmoothScroll(e, 'nosotros')}>
                    Nosotros
                </a>
            </li>
            <li>
                <a href="#faq" onClick={(e) => handleSmoothScroll(e, 'faq')}>
                    FAQ
                </a>
            </li>
        </ul>
    );
}

export default Navigation;
