import React from 'react';
import Select, { components, ControlProps, StylesConfig } from 'react-select';

import CopyIcon from '../../assets/images/icon/copy.png';

const Control = ({ children, selectProps, ...props }: ControlProps<any, false>) => {
    const { icon, onIconClick } = selectProps || {};
    return (
        <components.Control {...props}>
            {children}
            <img
                src={icon}
                alt="Icon"
                style={{ marginLeft: '8px', height: '15px', cursor: 'pointer' }}
                onMouseDown={onIconClick}
            />
        </components.Control>
    );
};

const customStyles: StylesConfig<any, false> = {
    control: (styles) => ({
        ...styles,
        backgroundColor: 'white',
        color: 'black',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        paddingTop: '0.4rem',
        paddingBottom: '0.4rem',
    }),
    // Otros estilos...
};

function TrafficHomeOne({ className, data, notify }) {
    if (!data) {
        return 'No hay data disponible';
    }

    const countries = data.country.map(({ countryName, profilePhoto }) => ({
        value: countryName,
        label: countryName,
        // icon: `http://localhost:3001${profilePhoto}`,
        icon: `https://dash.teresaremesas.com${profilePhoto}`,
    }));

    const ICONS = {};

    countries.forEach((country) => {
        ICONS[country.value] = country.icon;
    });

    const banksByCountry = {};

    data.bank.forEach(({ country: { countryName }, ...bankData }) => {
        if (!banksByCountry[countryName]) {
            banksByCountry[countryName] = [];
        }
        banksByCountry[countryName].push(bankData);
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectedOption, setSelectedOption] = React.useState(countries[0]);

    const handleCopy = (dataToCopy) => {
        navigator.clipboard.writeText(dataToCopy);
        notify();
    };

    return (
        <section id="horarios" className={`appie-traffic-area pb-40 ${className || ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="appie-traffic-title">
                            <h3 className="title">Horarios</h3>
                            <p className="BankCaption">
                                Trabajamos los Lunes a Sábado de 10:00 AM a 7:00 PM (Hora Colombia)
                            </p>
                        </div>
                        <div className="appie-traffic-title">
                            <h3 className="title">Cuentas bancarias activas</h3>
                            <p className="BankCaption">
                                Estas son las cuentas en las que puedes consignar o transferir el
                                dinero que quieres enviar.
                            </p>
                            <div className="BankCountrySelect">
                                <Select
                                    icon={ICONS[selectedOption.value]}
                                    components={{ Control }}
                                    name="icon"
                                    options={countries}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    styles={customStyles}
                                    onChange={(selected) => setSelectedOption(selected)}
                                    value={selectedOption}
                                />
                            </div>
                        </div>
                        <div className="row" style={{ justifyContent: 'center' }}>
                            {banksByCountry[selectedOption.label] &&
                                banksByCountry[selectedOption.label].map((bank, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <div className="col-lg-6 col-md-6" key={index}>
                                        <div
                                            className="appie-single-service-2 mt-30 d-flex bank"
                                            onClick={() =>
                                                handleCopy(
                                                    `Banco: "${bank.bankName}", Nombre: "${bank.bankAccountName}", Numero de Cuenta: "${bank.bankAccountNumber}", Tipo de Cuenta: "${bank.bankType}"`
                                                )
                                            }
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <span
                                                className="CopyButton"
                                                onClick={() =>
                                                    handleCopy(
                                                        `Banco: "${bank.bankName}", Nombre: "${bank.bankAccountName}", Numero de Cuenta: "${bank.bankAccountNumber}", Tipo de Cuenta: "${bank.bankType}"`
                                                    )
                                                }
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <img
                                                    src={CopyIcon}
                                                    style={{ width: '20px' }}
                                                    alt="Icono de copiar"
                                                />
                                            </span>
                                            <div className="col-3">
                                                <div
                                                    className="icon Services"
                                                    style={{
                                                        backgroundColor: bank.bankColor,
                                                        borderRadius: '23px',
                                                        height: '100%',
                                                    }}
                                                ></div>
                                            </div>
                                            <div className="col-lg-9 BankInfo">
                                                <h4 className="title Bank">{bank.bankName}</h4>
                                                <p>Nombre: {bank.bankAccountName}</p>
                                                <p>Número de cuenta: {bank.bankAccountNumber}</p>
                                                <p>Tipo de cuenta: {bank.bankType}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TrafficHomeOne;
