import React from 'react';
import paso1 from '../../assets/images/Paso1.png';
import paso2 from '../../assets/images/paso2.png';
import paso3 from '../../assets/images/paso3.png';

function BlogHomeOne({ className }) {
    return (
        <>
            <section id="comofunciona" className={`appie-blog-area pt-90 pb-95 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-traffic-title">
                                <p className="BankCaption">Sigue los pasos</p>
                            </div>
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">¿Cómo funciona?</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={paso1} alt="" />
                                </div>
                                <div className="content">
                                    <h3 className="title">Paso 1</h3>
                                    <div className="blog-meta">
                                        <p>
                                            Consignas el dinero en las cuentas activas cumpliendo
                                            con las verificaciones de seguridad:{' '}
                                        </p>
                                        <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                                            <li className="Guaro" style={{ marginBottom: '10px' }}>
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        width: '5px',
                                                        height: '5px',
                                                        backgroundColor: 'black',
                                                        borderRadius: '50%',
                                                        marginRight: '5px',
                                                    }}
                                                ></span>
                                                En consignaciones, escribe con bolígrafo: Nombre y
                                                Apellido de quien recibe.
                                            </li>
                                            <li className="Guaro" style={{ marginBottom: '10px' }}>
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        width: '5px',
                                                        height: '5px',
                                                        backgroundColor: 'black',
                                                        borderRadius: '50%',
                                                        marginRight: '5px',
                                                    }}
                                                ></span>
                                                En transferencias, escribe en la referencia o
                                                concepto: Nombre y Apellido de quien recibe.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 blogCenter col-md-6">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="thumb">
                                    <img src={paso2} alt="" />
                                </div>
                                <div className="content">
                                    <h3 className="title">Paso 2</h3>
                                    <div className="blog-meta">
                                        <p>
                                            {' '}
                                            Nos envías el comprobante de pago y la cuenta de destino
                                            previamente con la verificación de seguridad para evitar
                                            retrasos en tu pago
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="thumb">
                                    <img src={paso3} alt="" />
                                </div>
                                <div className="content">
                                    <h3 className="title">Paso 3</h3>
                                    <div className="blog-meta">
                                        <p>
                                            {' '}
                                            Te enviaremos el pago en un período de 15 minutos hasta
                                            2 horas.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogHomeOne;
